<template>
  <div class="loading-overlay" v-if="isLoading">
    <div class="loading-container">
      <div v-if="isDownload">
        <div>
          {{ $t("alert.downloading") }}
        </div>
        <div class="mb-2 text-danger">
          {{ $t("alert.dontRefreshPage") }}
        </div>
        <b-progress :max="100" height="20px">
          <b-progress-bar
            :value="percentCompleted"
            :label="`${percentCompleted}%`"
            variant="success"
            animated
            show-progress
          ></b-progress-bar>
        </b-progress>
      </div>
      <div v-else>
        <div>
          {{ $t("alert.generating") }}
        </div>
        <div class="mb-2 text-danger">
          {{ $t("alert.dontRefreshPage") }}
        </div>
        <b-spinner
          style="width: 3rem; height: 3rem"
          variant="primary"
          type="grow"
          label="Spinning"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { BProgress, BProgressBar, BSpinner } from "bootstrap-vue";

export default {
  components: {
    BProgress,
    BProgressBar,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      isDownload: false,
      percentCompleted: 0,
    };
  },
  mounted() {
    this.$bus.$on("show-loading", (object) => {
      this.isDownload = object.isDownload;
      this.percentCompleted = Math.floor((object.current / object.total) * 100);
      this.isLoading = true;
    });
    this.$bus.$on("hide-loading", () => {
      this.isLoading = false;
    });
  },
};
</script>
