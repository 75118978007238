<template>
  <div class="loading-overlay" v-if="isLoading">
    <div class="loading-container">
      <div>
        {{
          $t("alert.syncing", {
            value: this.module.toLowerCase(),
          })
        }}
      </div>
      <div class="mb-2 text-danger">
        {{ $t("alert.dontRefreshPage") }}
      </div>
      <b-progress :max="100" :value="100" height="20px" animated> </b-progress>
    </div>
  </div>
</template>

<script>
import { BProgress, BProgressBar, BSpinner } from "bootstrap-vue";

export default {
  components: {
    BProgress,
    BProgressBar,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      module: "",
    };
  },
  mounted() {
    this.$bus.$on("show-syncing", (item) => {
      this.isLoading = true;
      this.module = item.module;
    });
    this.$bus.$on("hide-syncing", () => {
      this.isLoading = false;
    });
  },
};
</script>
